<template>
    <div>
        <div class="types">
            <div class="typeDiv">
                <div class="typef" @click="popupDuty" id="downList">职务</div>
                <div :class="isDutyShow?'small-icon-rotate':'small-icon'"></div>                
            </div>
            <div class="content-divide-sa">|</div>
            <div class="typeDiv">
                <div class="order" @click="showStaff" id="downOrderList">员工</div>
                <div :class="isStaffShow?'small-icon-rotate':'small-icon'"></div>                
            </div>
            <div class="content-divide-ac">|</div>
            <div class="typeDiv">
                <div class="sif" @click="showIf" id="downIfList">条件筛选</div>
                <div :class="isIfShow?'small-icon-rotate':'small-icon'"></div>                
            </div>

        </div> 

        <!-- 职务搜索 -->
        <van-popup v-model="isDutyShow" position="bottom">
            <div class="searchDiv">
                <van-search
                placeholder="请输入"
                v-model="dutyName"
                size="large"
                @input="quaryDutyList()"
                />
            </div>
            <van-picker
                show-toolbar
                value-key="dutyName"
                :columns="dutyArr"
                @cancel="cancelDutyOption"
                cancel-button-text="重置"
                @confirm="selectDutyOption"
            />
        </van-popup>

        <!-- 员工搜索 -->
        <van-popup v-model="isStaffShow" position="bottom">
            <!--搜索栏-->
            <div class="searchDiv">
                <van-search
                placeholder="请输入"
                v-model="staffName"
                size="large"
                @input="quaryStaffList()"
                />
            </div>
            <van-picker
                show-toolbar
                value-key="staffName"
                :columns="staffArr"
                @cancel="cancelStaffOption"
                cancel-button-text="重置"
                @confirm="selectStaffOption"
            />
        </van-popup>

        <!-- 条件筛选 -->
        <van-popup class="screen" v-model="maskShow" position="right">
            <div style="height: 70%;overflow: scroll">
                <div class="screety">部门类型</div>
                <div class="sty">
                    <span class="stye"  @click="changenos() " :class="{'bgcolor':-1==spanIndex}">不限</span>
                    <span class="stye" v-for="( item,index) in departmentTypeList" :key="index" @click="changeSpan(index)"
                        :class="{'bgcolor':index==spanIndex}">{{ item.dictionaryTitle}}</span>
                </div>
                <div class="screety">员工状态</div>
                <div class="sty">
                    <span class="stye" @click="pricechangenos()" :class="{'bgcolor':-1==pricecSIdex}">不限</span>
                    <span class="styeprice" v-for="(item,index) in staffStatusList" :key="index"
                        @click="pricechangeSpan(index)" :class="{'bgcolor':index==pricecSIdex}">{{item.dictionaryTitle}}</span>
                </div>
                <div class="scrsty" style="width: 94%;position: absolute;bottom: 4%;z-index: 100">
                    <div style="display: flex;align-items: center;white-space: nowrap">
                        <div @click="cleanSpan()"><img class="clean-icon" src="../../../assets/images/4.png"></div>
                        <div class="clean_button" @click="cleanSpan()">清空</div>

                    </div>
                    <div class="screen_button" @click="screenSpan()">筛选</div>
                </div>
            </div>
        </van-popup>


        <div class="potential">
            <!-- 部门检索 -->
            <div class="module-small" @click="showProject">
                <span class="project-name">{{
                    departmentName != undefined && departmentId != ''
                    ? departmentName
                    : '部门检索'
                }}</span>
                <img class="drop-down-image" src="../../../assets/images/drop-down.png" />
            </div>
            <!--部门检索 下拉菜单-->
            <van-popup v-model="isShowProjectMenu" position="bottom" style="max-height: 85%;">
                <div style="background-color: #f7f8fa">
                    <div class="choose-company-text">选择公司</div>
                    <van-row justify="center" style="background-color: #f7f8fa">
                        <div v-for="(item, index) in companyList" :key="index">
                            <van-col span="8" offset="4" class="company-col-style"
                            ><div
                                :class="
                                checkedIndex == index
                                    ? 'company-col-checked-style'
                                    : 'company-text-style'
                                "
                                @click="selectCompany(item, index)"
                            >
                                {{ item.departmentName ? item.departmentName : '部门检索' }}
                            </div></van-col
                            >
                        </div>
                    </van-row>
                </div>
                <van-tree-select
                    :active-id.sync="activeId"
                    :main-active-index.sync="activeIndex"
                    :items="items"
                    @click-nav="selectArea"
                    @click-item="selectDepartment"
                />
                <div class="search">
                    <van-button
                        style="width: 2rem; color: orangered;border-color: orangered"
                        plain
                        type="primary"
                        @click="resetDepartmentInfo"
                    >清空</van-button>
                    <van-button
                        @click="searchDepartmentInfo"
                        style="width: 2.8rem;margin-left: 4rem"
                        color="linear-gradient(to right, #ff6034, #ee0a24)"
                        size="normal"
                    >筛选</van-button>
                </div>
            </van-popup>





            <p class="subtitle">基本信息</p>
            <div class="panel">
              <div class="t_table">
                    <div class="t_body" >
                            <van-row class="itemFontStyle">
                                <van-col span="1" class="t_td"></van-col>
                                <van-col span="3" class="t_td">公司：</van-col>
                                <van-col span="1" class="t_td_1"></van-col>
                                <van-col span="5" class="t_td">{{ $route.query.company_name }}</van-col>
                                <van-col span="2" class="t_td"></van-col>
                                <van-col span="4" class="t_td">工资日期：</van-col>
                                <van-col span="1" class="t_td_1"></van-col>
                                <van-col span="5" class="t_td">{{ $route.query.salaryTime }}</van-col>
                            </van-row>
                            <van-row class="itemFontStyle">
                              <van-col span="1" class="t_td"></van-col>
                              <van-col span="4" class="t_td">应发人数：</van-col>
                              <van-col span="1" class="t_td_1"></van-col>
                              <van-col span="5" :class="'t_td_color'" @click="jumpPerDetail()">{{ salaryCount.count }}</van-col>
                              <van-col span="1" class="t_td"></van-col>
                              <van-col span="4" class="t_td">实发人数：</van-col>
                              <van-col span="1" class="t_td_1"></van-col>
                              <van-col span="5" class="t_td">{{ salaryCount.countNum }}</van-col>
                            </van-row>
                            <van-row class="itemFontStyle">
                              <van-col span="1" class="t_td"></van-col>
                              <van-col span="4" class="t_td">缓发人数：</van-col>
                              <van-col span="1" class="t_td_1"></van-col>
                              <van-col span="5" class="t_td">{{ salaryCount.delayCountNum }}</van-col>
                            </van-row>
                    </div>
                </div>
            </div>
            <p class="subtitle">应发工资(税前)</p>
            <div class="panel">
              <div class="t_table">
                <div class="t_body" >
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">基本工资：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.basePay }}</van-col>
                  </van-row>
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">底薪：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.baseSalary }}</van-col>
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">交通补贴：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.trafficSubsidy }}</van-col>
                  </van-row>
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">通讯补贴：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.communicationSubsidy }}</van-col>
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">年资：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.seniority }}</van-col>
                  </van-row>
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="5" class="t_td">税前补扣项：</van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.addtion }}</van-col>
                    <!--                                <van-col span="0" class="t_td"></van-col>-->
                      <van-col span="4" class="t_td">岗位工资：</van-col>
                      <van-col span="1" class="t_td_1"></van-col>
                      <van-col span="5" class="t_td">{{ salaryCount.postWage }}</van-col>
                  </van-row>
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">出房重奖：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.outBonus }}</van-col>
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">收房重奖：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.receiveBonus }}</van-col>
                  </van-row>
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">剩余佣金：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.afterPercentageAmount }}</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="4" class="t_td">徒弟抽成：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.inputPercentage }}</van-col>
                  </van-row>
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">收房佣金：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.achievements }}</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="4" class="t_td">考勤：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.workCheck }}</van-col>
                  </van-row>
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">底薪考核：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.baseSalaryAssessment }}</van-col>
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">火房考核：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.kitchen }}</van-col>
                  </van-row>

                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">应发工资：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.newPayableAmount }}</van-col>
                  </van-row>
                  <!-- </van-row> -->
                </div>
              </div>
            </div>

            <p class="subtitle">应税工资(税前)</p>
            <div class="panel">
              <div class="t_table">
                <div class="t_body" >
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">五险一金：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.socialSecurity }}</van-col>
                  </van-row>
                  <van-row class="itemFontStyle">
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">应税工资：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.taxableSalary }}</van-col>
                    <van-col span="1" class="t_td"></van-col>
                    <van-col span="4" class="t_td">应税金额：</van-col>
                    <van-col span="1" class="t_td_1"></van-col>
                    <van-col span="5" class="t_td">{{ salaryCount.extraSubsidy }}</van-col>
                  </van-row>
                  <!-- </van-row> -->
                </div>
              </div>
            </div>

            <p class="subtitle">实发工资(税后)</p>
            <div class="panel">
                <div class="t_table">
                    <div class="t_body" >
                            <van-row class="itemFontStyle">
                                <van-col span="1" class="t_td"></van-col>
                                <van-col span="4" class="t_td">互助金：</van-col>
                                <van-col span="1" class="t_td_1"></van-col>
                                <van-col span="5" class="t_td">{{ salaryCount.mutualFund }}</van-col>
                                <van-col span="1" class="t_td"></van-col>
                                <van-col span="4" class="t_td">税：</van-col>
                                <van-col span="1" class="t_td_1"></van-col>
                                <van-col span="5" class="t_td">{{ salaryCount.tax }}</van-col>
                            </van-row>
                            <van-row class="itemFontStyle">
                              <van-col span="1" class="t_td"></van-col>
                              <van-col span="5" class="t_td">税后补扣项：</van-col>
                              <van-col span="5" class="t_td">{{ salaryCount.taxedAddition }}</van-col>
                              <van-col span="4" class="t_td">缓发：</van-col>
                              <van-col span="1" class="t_td_1"></van-col>
                              <van-col span="5" class="t_td">{{ salaryCount.delayAmount }}</van-col>
                            </van-row>
                            <van-row class="itemFontStyle">
                                <van-col span="1" class="t_td"></van-col>
                                <van-col span="4" class="t_td">实发工资：</van-col>
                                <van-col span="1" class="t_td_1"></van-col>
                                <van-col span="5" class="t_td">{{ salaryCount.actualAmount }}</van-col>
                            </van-row>
                        <!-- </van-row> -->
                    </div>
                </div>
            </div>

          <p class="subtitle">实发工资(税后)</p>
          <div class="panel">
            <div class="t_table">
              <div class="t_body" >
                <van-row class="itemFontStyle">
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="4" class="t_td">历史欠款：</van-col>
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="5" class="t_td">{{ salaryCount.owedAmount }}</van-col>
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="4" class="t_td">本月欠款：</van-col>
                  <van-col span="1" class="t_td_1"></van-col>
                  <van-col span="5" class="t_td">{{ salaryCount.debtAmount }}</van-col>
                </van-row>
                <van-row class="itemFontStyle">
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="4" class="t_td">最终发放：</van-col>
                  <van-col span="1" class="t_td_1"></van-col>
                  <van-col span="5" class="t_td">{{ salaryCount.salaryAmount }}</van-col>
                </van-row>
              </div>
            </div>
          </div>

            <div v-if="isApprovalShow">
                <div class="panel radio_body withMargin">
                    <p style="marginBottom:15px">
                    <span :class="isChoose ? 'dot is_dot' : 'dot'"></span>
                    审核结果
                    </p>
                    <div class="choose">
                        <div :class="isPass == 0?'choose_btn pass': 'choose_btn'" @click="multipleChoice(0)">通过</div>
                        <div class="line"></div>
                        <div :class="isPass == 1?'choose_btn reject': 'choose_btn'" @click="multipleChoice(1)">驳回</div>
                    </div>
                </div>

                <div class="panel">
                    <p class="yijian">审批意见</p>
                    <div class="text_body">
                    <van-field v-model="opinion"  type="textarea" class="mes_body" />
                    </div>
                </div>
                <div class="rule"></div>
                <div class="sub_btn">
                    <van-button  :class="!isChoose  ||( istype==1 && opinion=='') ?'saveButton_Disable':'saveButton_Enable'" :disabled="!isChoose  || ( istype==1 && opinion=='') " size="large" @click="save">保存
                    </van-button>
                </div>
            </div>    

        </div>   
        
        <loading v-if="loadingFlag"></loading>
    </div>

</template>


<script>
import { Field, Button, Row, Col, Popup, Cell, Search, Picker,TreeSelect, } from 'vant'
import {queryAuthLevelDepartment,decorationDetailInit,queryBaseData,queryStaffList,queryPayrollApprovalDetailListCount,queyDutySelList,wageApproval} from "../../../getData/getData";
import {getStaffId, globaluserId,responseUtil,money} from "../../../libs/rongxunUtil";

export default {
    name: 'payslip',
    components: {
        [Button.name]: Button,
        [Field.name]:Field,
        [Row.name]: Row,
        [Col.name]: Col,
        [Popup.name]: Popup,
        [Cell.name]: Cell,
        [Search.name]: Search,
        [Picker.name]: Picker,
        [TreeSelect.name]: TreeSelect,
    },
    data(){
        return{
            //搜索项
            // 下拉
            isDutyShow:false,  //职务
            dutyName:'',  ///筛选输入
            dutyArr:[],  //职务列表
            duty_id:'',  //选择的职务id
            isStaffShow:false,
            staffName:'',
            staffArr:[],
            staff_id:'',  //员工id

            maskShow:false,  //条件搜索框
            isIfShow:false,
            staffStatusList:[],  //员工状态
            departmentTypeList:[],  //部门类型
            salaryStatusList:[],  //缓发状态
            addtionTypeList:[],  //补扣类型

            spanIndex:-1,
            pricecSIdex:-1,

            //部门检索
            isShowProjectMenu:false,  //部门搜索
            companyList: [],  //公司列表
            level:'',
            departmentId:'',
            departmentName:'',
            departmentAuthData: {},
            functionId: '391',
            checkedIndex: 0,
            activeId: -1,
            activeIndex: 0,
            departmentLevelType:'',
            staffStatus:'',
            items: [
                {
                    text: '浙江',
                    children: [
                        { text: '杭州', id: 1 },
                        { text: '温州', id: 2 },
                    ],
                },
                {
                    text: '辽宁',
                    children: [
                        { text: '沈阳', id: 3 },
                        { text: '大连', id: 4 },
                    ],
                },
                {
                    text: '江苏',
                    children: [
                        { text: '南京', id: 5 },
                        { text: '无锡', id: 6 },
                    ],
                },
            ],

            //工资条
            salaryCount:{
              count:0,
              countNum:0,
              delayCountNum:0,
              basePay:0.00,
              workCheck:0.00,
              postWage:0.00,
              mutualFund:0.00,
              seniority:0.00,
              trafficSubsidy:0.00,
              communicationSubsidy:0.00,
              socialSecurity:0.00,
              addtion:0.00,
              outBonus:0.00,
              receiveBonus:0.00,
              reAchievements:0.00,
              achievements:0.00,
              baseSalary:0.00,
              kitchen:0.00,
              newPayableAmount:0.00,
              tax:0.00,
              delayAmount:'',
              actualAmount:'',
              salaryAmount:'',
              owedAmount:'',
              debtAmount:'',
              taxableSalary:0.00,
              extraSubsidy:0.00,
              taxedAddition:0.00
            },


            //审批
            isApprovalShow:false,
            isChoose:true,  //是否选择审批意见
            isPass:0,
            istype:0,  //审批意见（0通过1驳回
            opinion:'',  //审批意见
            loadingFlag:false,

        }
    },
    mounted(){
        if(localStorage.getItem('departmentLevelType')!=undefined){
            this.departmentLevelType = JSON.parse(localStorage.getItem('departmentLevelType')); //先读取local里存储的历史记录
        }
        if(localStorage.getItem('staffStatus')!=undefined){
            this.staffStatus = JSON.parse(localStorage.getItem('staffStatus')); //先读取local里存储的历史记录
        }
        if(localStorage.getItem('level')!=undefined){
            this.level = JSON.parse(localStorage.getItem('level')); //先读取local里存储的历史记录
        }
        if(localStorage.getItem('department_id')!='undefined'){
            this.department_id = JSON.parse(localStorage.getItem('department_id')); //先读取local里存储的历史记录
        }
        if(localStorage.getItem('departmentName')!=undefined){
            this.departmentName = JSON.parse(localStorage.getItem('departmentName')); //先读取local里存储的历史记录
        }
        if(localStorage.getItem('duty_id')!=undefined){
            this.duty_id = JSON.parse(localStorage.getItem('duty_id')); //先读取local里存储的历史记录
        }
        if(localStorage.getItem('staff_id')!='undefined'){
            this.staff_id = JSON.parse(localStorage.getItem('staff_id')); //先读取local里存储的历史记录
        }
        if(localStorage.getItem('payrollmanage_id')!='undefined'){
            this.payrollmanage_id = JSON.parse(localStorage.getItem('payrollmanage_id')); //先读取local里存储的历史记录
        }
        if(this.$route.query.type=='approval'){
            this.isApprovalShow = true
        }
        if(this.$route.query.type=='detail'){
            this.isApprovalShow = false
        }
        //统计
        // this.queryPayrollApprovalDetailListCount()
        //详情
        //公司初始化
        this.queryAuthLevelDepartment()
        //职务
        this.quaryDutyList()
        //人员
        this.quaryStaffList()
        this.initDictionaryData()
    },
    methods:{
        //职务筛选
        popupDuty(){
            this.isDutyShow = true
        },
        quaryDutyList() {
            let that = this
            let data = {}
            data.searchName = that.dutyName
            //queryRentTenant  seeWithUserList
            queyDutySelList(data).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                    that.dutyArr = response.data.data.dutyList
                })
            })
        },
        cancelDutyOption(){
            this.duty_id = ''
            this.queryPayrollApprovalDetailListCount()
            this.isDutyShow = false
        },
        selectDutyOption(item) {
            this.duty_id = item.id
            this.queryPayrollApprovalDetailListCount()
            this.isDutyShow = false
        },

        //人员筛选
        showStaff(){
            this.isStaffShow = true
        },
        quaryStaffList(){
            let that = this
            let data = {}
            data.searchName = that.staffName
            //queryRentTenant  seeWithUserList
            queryStaffList(data).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                  that.staffArr=response.data.data.data
                })
            })
        },

        cancelStaffOption(){
            this.staff_id = ''
            this.queryPayrollApprovalDetailListCount()
            this.isStaffShow = false
        },
        selectStaffOption(item){
            this.staff_id = item.id
            this.queryPayrollApprovalDetailListCount()
            this.isStaffShow = false
        },


        //条件筛选
        showIf: function () {
            this.maskShow = true;
        },
        changenos: function () {
            this.spanIndex = -1;
        },
        changeSpan: function (index) {
            // console.log(index)
            this.spanIndex = index
        },
        pricechangenos: function (index) {
            this.pricecSIdex = -1;
        },
        pricechangeSpan: function (index) {
            this.pricecSIdex = index;
        },

        //清空
        cleanSpan: function () {
                this.spanIndex = -1;
                this.pricecSIdex = -1;
                // this.houseSIdex = -1;
                // this.dateSIdex = 0;
                // this.charaSIdex.splice(0, this.charaList.length);
                // this.charaSnosIdex= -1;

            },
        screenSpan: function () {
            // 部门类型
            if( this.spanIndex!= -1) {
                this.departmentLevelType = this.departmentTypeList[this.spanIndex].id
            }else{
                this.departmentLevelType =''
            }
            // 员工状态
            if( this.pricecSIdex!= -1) {
                this.staffStatus = this.staffStatusList[this.pricecSIdex].dictionaryValue
            }else{
                this.staffStatus =''
            }
            //统计
            this.queryPayrollApprovalDetailListCount()
            //详情
            this.maskShow = false;
        },

        //显示部门检索下拉菜单
        showProject() {
            this.isShowProjectMenu = true
        },

        //查看详情
        queryPayrollApprovalDetailListCount(){
          // salaryData
            var that = this
            let initData = {}
            initData.departmentLevelType = this.departmentLevelType  //部门类型
            initData.staffStatus = this.staffStatus  //员工状态
            initData.level = this.level
            initData.department_id = this.departmentId  //部门id
            initData.departmentName = this.departmentName
            initData.duty_id = this.duty_id  //职务
            initData.staff_id = this.staff_id  //员工
            initData.user_id = getStaffId()
            initData.payrollmanage_id = that.$route.query.payrollmanage_id   //this.salaryHistory_id
            queryPayrollApprovalDetailListCount(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.salaryCount = response.data.data.data[0]
                    that.salaryCount.recordId = response.data.data.recordId
                    that.salaryCount.count = response.data.data.data[0].countNum  //总人数
                    that.salaryCount.countNum = response.data.data.data[0].actualCount  //实发人数
                    that.salaryCount.delayCountNum = response.data.data.data[0].delayCount  //缓发人数
                    that.salaryCount.lastDelayAmount = money(response.data.data.data[0].lastDelayAmount)//上月缓发
                    // 基本工资、考勤、岗位工资、互助金、交通补贴、通讯补贴、年资、五险一金、补扣项、出房重奖、收房重奖、实际出房佣金、收房佣金、底薪考核、火房考核、应发工资、税、缓发、实发工资 -->
                    that.salaryCount.basePay = money(response.data.data.data[0].basePay)
                    that.salaryCount.workCheck = money(response.data.data.data[0].workCheck)
                    that.salaryCount.postWage = money(response.data.data.data[0].postWage)
                    that.salaryCount.mutualFund = money(response.data.data.data[0].mutualFund)
                    that.salaryCount.trafficSubsidy = money(response.data.data.data[0].trafficSubsidy)
                    that.salaryCount.communicationSubsidy = money(response.data.data.data[0].communicationSubsidy)
                    that.salaryCount.seniority = money(response.data.data.data[0].seniority)
                    that.salaryCount.socialSecurity = money(response.data.data.data[0].socialSecurity)
                    that.salaryCount.addtion = money(response.data.data.data[0].addtion)
                    that.salaryCount.outBonus = money(response.data.data.data[0].outBonus)
                    that.salaryCount.receiveBonus = money(response.data.data.data[0].receiveBonus)
                    that.salaryCount.actualReAchievements = money(response.data.data.data[0].actualReAchievements)
                  that.salaryCount.afterPercentageAmount = money(response.data.data.data[0].afterPercentageAmount)
                  that.salaryCount.inputPercentage = money(response.data.data.data[0].inputPercentage)

                  that.salaryCount.achievements = money(response.data.data.data[0].achievements)
                    that.salaryCount.baseSalary = money(response.data.data.data[0].baseSalary)
                    that.salaryCount.kitchen = money(response.data.data.data[0].kitchen)
                    that.salaryCount.newPayableAmount = money(response.data.data.data[0].newPayableAmount)
                    that.salaryCount.tax = money(response.data.data.data[0].tax)
                    that.salaryCount.delayAmount = money(response.data.data.data[0].delayAmount)
                    that.salaryCount.actualAmount = money(response.data.data.data[0].actualAmount)
                    that.salaryCount.owedAmount = money(response.data.data.data[0].owedAmount)
                    that.salaryCount.debtAmount = money(response.data.data.data[0].debtAmount)
                    that.salaryCount.salaryAmount = response.data.data.data[0].salaryAmount?money(response.data.data.data[0].salaryAmount):money(response.data.data.data[0].actualAmount)


                  //税前应税工资
                    that.salaryCount.taxableSalary = money(response.data.data.data[0].taxableSalary) //应税工资
                    that.salaryCount.extraSubsidy = money(response.data.data.data[0].extraSubsidy) //应税金额

                    that.salaryCount.taxedAddition = money(response.data.data.data[0].taxedAddition)//税后补扣项


                  // this.priceRenderGenerator("lastDelayAmount")
                })
            })
        },

        //查看详情
        jumpPerDetail(index){
            localStorage.setItem('departmentLevelType', JSON.stringify(this.departmentLevelType))
            localStorage.setItem('staffStatus', JSON.stringify(this.staffStatus))
            localStorage.setItem('level', JSON.stringify(this.level))
            if(this.department_id){
                localStorage.setItem('department_id', JSON.stringify(this.department_id))
            }
            localStorage.setItem('departmentName', JSON.stringify(this.departmentName))
            localStorage.setItem('duty_id', JSON.stringify(this.duty_id))
            localStorage.setItem('staff_id', JSON.stringify(this.staff_id))
            localStorage.setItem('payrollmanage_id', JSON.stringify(this.payrollmanage_id))
            this.$router.push({
                name: 'payslipDetail',
                query: {
                    departmentLevelType : this.departmentLevelType,  //部门类型
                    staffStatus : this.staffStatus,  //员工状态
                    level : this.level,
                    department_id : this.departmentId,  //部门id
                    departmentName : this.departmentName,
                    duty_id : this.duty_id,  //职务
                    staff_id : this.staff_id , //员工
                    payrollmanage_id:this.$route.query.payrollmanage_id,
                }
            })
        },





        //1通过0驳回
        multipleChoice(isPass) {
            if(isPass == 0){
                this.istype=0;
            }else {
                this.istype=1;
            }
            //审核结果
            this.isChoose = true;
            //this.grade= 0;
            this.isPass = isPass
        },
        save(){
          this.loadingFlag = true
          let that = this
          let queryData = {}
          queryData.payrollmanage_id = that.$route.query.payrollmanage_id
          queryData.salaryHistoryId = that.$route.query.salaryhistory_id
          queryData.recordId = that.salaryCount.recordId
          queryData.staffId=getStaffId()
          queryData.radioSelect=this.isPass
          queryData.remarks=this.opinion
          wageApproval(queryData).then(function (response) {
              that.loadingFlag = false
              if(response.data.code!=-1){
                responseUtil.dealResponse(that, response, () => {
                    responseUtil.alertOK(that,'操作成功')
                    that.$router.go(-1)
                })
              }else{
                responseUtil.alertMsg(that,response.data.msg)
              }
          })                      
        },



        queryAuthLevelDepartment() {
            const that = this
            let initData = {}
            initData.staff_id = getStaffId() //员工id
            initData.function_id = that.functionId //方法模块id
            queryAuthLevelDepartment(initData).then(res =>
                responseUtil.dealResponse(that, res, () => {
                    that.departmentAuthData = res.data.data
                    let allList = that.departmentAuthData.list
                    if (allList != null) {
                        that.companyList = allList.filter(item => item.level == 1)
                    }
                    let departmentId = that.departmentAuthData.roleDepartmentId
                    that.departmentId = departmentId
                    let item = that.departmentAuthData.list.find(
                        item => item.id == departmentId
                    )
                    that.departmentName = item ? item.departmentName || '' : ''
                    that.initOtherDepartment()
                    that.searchDepartmentInfo()
                })
            )
        },
        initOtherDepartment() {
            let that = this
            let allList = that.departmentAuthData.list
            let departmentId = that.departmentId
            let threeLevelDepartments = allList.filter(item => item.level == 3)
            let fiveLevelDepartments = allList.filter(item => item.level == 5)
            let levelDepartments = threeLevelDepartments
                .filter(item => {
                    let levelTop = item.levelTop || ''
                    return levelTop.indexOf(departmentId) != -1
                })
                .map(item => {
                    let result = {}
                    result.children = fiveLevelDepartments
                        .filter(child => {
                            let levelTop = child.levelTop || ''
                            return levelTop.indexOf(',' + item.id) != -1
                        })
                        .map(child => {
                            let childResult = {}
                            childResult.text = child.departmentName
                            childResult.id = child.id
                            childResult.level = child.level
                            return childResult
                        })
                    result.text = item.departmentName
                    result.value = item.id
                    result.level = item.level
                    return result
                })
            that.items = levelDepartments // 公司列表
        },
        //选择公司，联动下方区、部
        selectCompany(item, index) {
            let that = this
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(that, '权限不足')
                return
            }
            this.checkedIndex = index
            this.level = item.level
            this.departmentId = item.id
            this.departmentName = item.departmentName
            this.initOtherDepartment()
        },
        selectArea(value) {
            let item = this.items[value]
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(this, '权限不足')
                return
            }
            this.level = item.level
            this.departmentId = item.value
            this.departmentName = item.text
        },
        selectDepartment(data) {
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(this, '权限不足')
                return
            }
            this.level = data.level
            this.departmentId = data.id
            this.departmentName = data.text
        },
        searchDepartmentInfo() {
            //统计
            this.queryPayrollApprovalDetailListCount()
            //详情
            this.isShowProjectMenu = false
        },
        resetDepartmentInfo() {
            let departmentId = this.departmentAuthData.roleDepartmentId
            let item = this.departmentAuthData.list.find(
                item => item.id == departmentId
            )
            this.departmentName = item ? item.departmentName || '' : ''
            this.departmentId = departmentId
            this.isShowProjectMenu = false
            this.searchDepartmentInfo()
        },
        // 获取字典数据
        initDictionaryData: function() {
            var that = this
            let initData = {}
            initData.user_id = getStaffId()
            initData.dbName = ['departmentType','addTionType']
            initData.fdName = ['STAFFSTATUSMAP',"APPROVESTATUSMAP",'STAFFSALARYSTATUSMAP']
            queryBaseData(initData).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                    that.staffStatusList = response.data.data.STAFFSTATUSMAP
                    // that.approvalStatusList = response.data.data.APPROVESTATUSMAP
                    that.salaryStatusList = response.data.data.STAFFSALARYSTATUSMAP
                    that.departmentTypeList = response.data.data.departmentType
                    that.addtionTypeList = response.data.data.addTionType
                })
            })
        },

    }
}
</script>

<style lang="less" scoped>
  @redius: 8px;
  @border: 1px solid rgb(244, 244, 244);

  .part-inputpart {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
  }
  .part-inputpart-dropDownList{
    height: 300px;
    /*垂直方向滚动*/
    /*overflow-y: scroll;*/
    overflow: auto;
    width: 99.5%;
    position: absolute;
    z-index: 99;
    margin:4px 0 0 -19px;
  }
  .diamod {
    width: 8px;
    height: 8px;
    background-color: #3B98FF;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 17px 7px 0 18px;
    display: inline-block;
  }

  .t_title {
    background-color: white;
    color:#33ADFF;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    height: 20px;
  }
  .approve-opinion{
    margin-top:10px
  }
  .potential {
    width: 375px;
    background-color: rgb(250, 250, 250);
    padding: 15px 15px 0;
    box-sizing: border-box;
    overflow: hidden;
    color: #252525;


    .sub_btn {
      margin: 30px 0 70px;
    }



    .subtitle {
      padding: 5px 0px;
      color: rgb(244, 99, 76);
      margin-top: 15px;
      font-weight: 700;
      font-size: 13px;
    }


    /*保存按钮不可点击样式*/
    .saveButton_Disable{
      /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
      background-color: rgba(184, 184, 184, 0.2);
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      line-height: 1.22rem;
      margin-left: 0;
      text-align: center;
    }
    /*保存按钮可点击样式*/
    .saveButton_Enable{
      background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      line-height: 1.22rem;
      text-align: center;
    }

    .panel {
      width: 345px;
      background-color: rgb(255, 255, 255);
      border-radius: @redius;
      position: relative;
      font-size: 14px;
      text-align: left;
      overflow: hidden;


      &.withMargin {
        margin: 16px 0;
      }

      &>p {
        padding: 15px 15px 0px;
        font-weight: bolder;
        /*color: #252525;*/

      }

      &>p>span {
        font-weight: normal;
        color: rgb(190, 190, 190);
      }



      .text_body {
        padding: 15px;

        .mes_body {
          border-radius: @redius;
          background-color: rgb(250, 250, 250);
        }
      }
    }

    // 单选框
    .radio_body {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;

      .dot {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: rgb(136, 136, 136);
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 6px;

        &.is_dot {
          background-color: rgb(251, 91, 64);
        }
      }

      .line {
        width: 2px;
        height: 12px;
        background-color: rgb(250, 250, 250);
      }

      .choose {
        margin-right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;

        .choose_btn {
          padding: 5px;
          margin: 10px;
          color: rgb(136, 136, 136);
          cursor: pointer;

          &.pass {
            color: rgb(251, 91, 64);
            font-weight: bolder;
          }

          &.reject {
            color: red;
            font-weight: bolder;
          }
        }
      }
    }

    // 循环表单
    .t_table {
      .t_header {
        text-align: left;
        color: rgb(136, 136, 136);
        /*font-weight: bolder;*/
        font-size: 10px;
        height: 20px;
        line-height: 21px;
        background-color: rgba(243, 249, 255, 1);
      }

      .t_title {
        //background-color: rgb(244, 249, 255);
        color:#33ADFF;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        height: 20px;
      }

      .t_td {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
      }
      .t_td_1 {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        padding-left: 10px;
      }
      .t_td_color{
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        color: #f86513;
        text-decoration:underline;
      }
    }

  }
</style>


<style lang="less" scoped>
/*项目选择模块*/
.module-small {
//   width: 92%;
  height: 1.35rem;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;

}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  padding: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式 未选中*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  /*background-color:#f7f8fa;*/
  border-radius: 0.1rem;
  font-size: 17px;
}
</style>


<style lang="less" scoped>
    .types {
        width: 94%;
        // height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #FFFFFF;
        padding: 0.27rem;
        font-size: 12px;
    }

    .typeDiv{
        display: flex;
        align-items: center;
    }

    .typef {
        // width: 60px;
        text-align: right;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 18px;
    }

    .order {
        // width: 80px;
        text-align: right;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 10px;
    }

    .sif {
        width: auto;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 21px;
    }

    .small-icon {
        width: 8px;
        height: 8px;
        margin-top: 3px;
        margin-left: 2px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .small-icon-rotate {
        width: 8px;
        height: 8px;
        margin-top: 3px;
        margin-left: 2px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform: rotate(180deg);
    }

    .screen {
        width: 80%;
        height: 90%;
        padding-top: 3.5rem;

    }
    .screety {
        font-size: 13px;
        font-weight: bold;
        /*margin-left: 20px;*/
        /*margin-top: 25px;*/
      margin: 20px;
    }

    .scrsty {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }

    /*垃圾桶*/
    .clean-icon {
        height: 20px;
        /*padding: 0 5px 0 23px;*/
      margin-right: 10px;
      margin-left: 10px;
        width: 16px;
    }

    /*清空*/
    .clean_button {
        /*position: absolute;*/
        /*top: 83px;*/
        font-size: 14px;
        color: rgba(255, 93, 59, 1);
    }

    /*筛选按钮*/
    .screen_button {
        /*position: absolute;*/
        /*left: 150px;*/
        /*padding: 11px 31px;*/
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 6px;
        color: rgb(255, 255, 255);
        border: none;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    }

    /*遮罩*/
    .black_overlay {
        display: none;
        position: absolute;
        top: 38%;
        left: 0%;
        width: 100%;
        height: 62%;
        background-color: black;
        z-index: 1001;
        -moz-opacity: 0.8;
        opacity: .80;
        filter: alpha(opacity=88);
    }

    /*遮罩*/
    .black_order_overlay {
        display: none;
        position: absolute;
        top: 45%;
        left: 0%;
        width: 100%;
        height: 55%;
        background-color: black;
        z-index: 1001;
        -moz-opacity: 0.8;
        opacity: .80;
        filter: alpha(opacity=88);
    }


    .sty {
        margin-left: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
    }

    .stye {
        white-space: nowrap; /*强制span不换行*/
        display: inline-block; /*将span当做块级元素对待*/
        overflow: hidden; /*超出宽度部分隐藏*/
        text-overflow: ellipsis; /*超出部分以点号代替*/
        line-height: 0.9; /*数字与之前的文字对齐*/
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 7px 12px;
      width: 24%;
        /*margin-right: 6px;*/
    }

    .styeprice {
        white-space: nowrap; /*强制span不换行*/
        display: inline-block; /*将span当做块级元素对待*/
        overflow: hidden; /*超出宽度部分隐藏*/
        text-overflow: ellipsis; /*超出部分以点号代替*/
        line-height: 0.9; /*数字与之前的文字对齐*/
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 7px 12px;
      width: 24%;
        /*margin-right: 16px;*/
    }

    .bgcolor {
        background: rgb(255, 221, 220);
        color: rgb(255, 91, 62);
        font-size: 12px;
        text-align: center;
        border-radius: 5px;
        width: 24%;
      /*display: flex;*/
      /*align-items: center;*/
      /*justify-content: center;*/
    }
    .selectList {
        position: fixed;
        z-index: 9;
        width: 100%;
        height: 220px;
        top: 8%;
        padding: 5px 0 14px 0;
        background-color: white;
    }

    .select {
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }

    .selectActive {
        color: #fe5e3a;
    }

    .reviews {
        width: 100%;
        overflow: visible;
        height: auto;
        position: absolute;
        top: 22%;
        background-color: #FFFFFF;
    }

    .content-divide-sa {
        font-size: 14px;
        /*margin: 15px 0 0 35px;*/
        color: #f4f4f4;
    }

    .content-divide-ac {
        font-size: 14px;
        /*margin: 15px 0 0 25px;*/
        color: #f4f4f4;
    }

    .content-divide-dos {
        font-size: 14px;
        margin: 15px 0 0 5px;
        color: #999999;
    }

    .keyAddress {
        font-size: 12px;
        /*height: auto;*/
        padding-top: 3px;
        /*padding-left: 17px;*/
        color: #7d7b7b;
    }
.rule {
  width: 98%;
  border: 1px solid #efefef;
  margin-top: 50px;
}
</style>